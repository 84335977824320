



















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop() title: any;
  @Prop() documentType: any;
  @Prop() documentTypeName: any;
  @Prop() seriesItems!: Array<any>;
  @Prop() availableSeriesYears!: Array<any>;
  @Prop() pageNumber: any;
  @Prop() perPage: any;
  @Prop() totalRows: any;
  @Prop() seriesYear: any;

  selectedSeriesYear: any;
  editItemsPerPage: any;

  created() {
    this.editItemsPerPage = this.perPage;
    this.selectedSeriesYear = this.seriesYear;
  }

  updated() {
    this.editItemsPerPage = this.perPage;
    if (this.selectedSeriesYear != null && !this.seriesItems.length) {
      this.onSeriesYearSelect(null);
    }
  }

  tableFields = [
    {
      label: 'Anul',
      key: 'seriesYear',
    },
    {
      label: 'Cod',
      key: 'seriesCode',
    },
    {
      label: 'Nr. început',
      key: 'startIndexPadded',
    },
    {
      label: 'Ult. număr',
      key: 'lastIndex',
    },
    {
      label: 'Detalii',
      key: 'description',
    },
    {
      label: 'Stare',
      key: 'isActive',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  onApplyItemsPerPage() {
    this.$emit('onApplyItemsPerPageEvent', { value: this.editItemsPerPage, type: this.documentType });
  }

  onSeriesYearSelect(value) {
    this.selectedSeriesYear = value;
    this.$emit('onSeriesYearSelectEvent', { value, type: this.documentType });
  }

  onCurrentGridPageChanged(page) {
    this.$emit('currentGridPageChangedEvent', { page, type: this.documentType });
  }

  onShowAddEditEvent(actionType, documentSeriesId = null, data: any = null) {
    this.$emit('onShowAddEdit', {
      documentType: this.documentType,
      actionType,
      documentSeriesId,
      data,
    });
  }

  removeSeriesEvent(documentSeriesId) {
    this.$emit('removeSeries', {
      documentType: this.documentType,
      documentSeriesId,
    });
  }

  enableSeriesEvent(documentSeriesId) {
    this.$emit('enableSeries', {
      documentSeriesId,
    });
  }

  disableSeriesEvent(documentSeriesId) {
    this.$emit('disableSeries', {
      documentSeriesId,
    });
  }
}

